<template>
  <el-row class="container">
    <total-head :froms="'unite-study'"></total-head>
    <el-row class="e-flex e-flex-center e-m-t-30">
      <el-col :span="14">
        <el-row class="main-body">
          <div class="e-flex body-menu">
            <img src="../../assets/class-list.png" alt="">
            <el-select v-model="school" @change="getClasses" placeholder="选择学校" class="e-m-l-34 selector">
              <el-option
                  v-for="item in optionsSchool"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
            <el-select v-model="grade"  @change="getClasses" placeholder="选择年级" class="e-m-l-10 selector">
              <el-option
                  v-for="item in optionsGrade"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
            <el-select v-model="classes" placeholder="选择班级" class="e-m-l-10 selector">
              <el-option
                  v-for="item in optionClasses"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
            <el-button type="success" icon="el-icon-search" class="e-m-l-30" @click="searchCourses">查询课程</el-button>
          </div>
          <div class="e-p-b-40">
            <el-table
                max-height="600px"
                :data="tableData"
                :header-cell-style="headerRow"
                style="width: 100%">
              <el-table-column
                  label="课程序号"
                  align="center"
                  prop="number">
              </el-table-column>
              <el-table-column
                  label="科目"
                  align="center"
                  prop="subject_name">
              </el-table-column>
              <el-table-column
                  label="课程名称"
                  align="center"
                  prop="name">
              </el-table-column>
              <el-table-column
                  label="学习时间"
                  align="center"
                  >
                  <template slot-scope="scope">
                  {{scope.row.start_time}} 至 {{scope.row.stop_time}}
                  </template>
              </el-table-column>
              <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                  <div class="btn-img" @click="startLearn(scope.row)"></div>
                </template>
              </el-table-column>
            </el-table>
            <div class="e-m-t-30">
              <pagination :pageIndex="pageIndex" :pageSize="pageSize" :pageTotal="pageTotal" @pageChange="pageChange"></pagination>
            </div>
          </div>
        </el-row>
      </el-col>
    </el-row>
  </el-row>
</template>

<script>
import TotalHead from "@/components/total-head"
import Pagination from "@/components/pagination"
export default {
  name: "unite-study",
  components: {
    TotalHead,
    Pagination
  },
  data() {
    return {
      pageIndex:1,
      pageSize:10,
      pageTotal:0,
      optionsSchool: [],
      optionsGrade:[],
      optionClasses:[],
      school: '', //所选学校
      grade: '',  //所选年级
      classes: '', //所选班级
      tableData: [],
      headerRow:{
        background:'#F2FBF7',
        padding:'20px 0',
        color:'#05170F',
        fontSize:'14px'
      }
    }
  },
  created() {
    this.initData();
  },
  methods: {
    initData(){
      let self = this;
      self.$get({
          url: self.$wp + '/unitestudy/schoolsAndGrades',data: {}
        }).then(function (res) {
          console.log(res)
          self.optionsSchool = res['data']['schools']
          self.optionsGrade = res['data']['grades']
      })
    },
    getClasses(){
      this.classes = '';
      this.optionClasses=[];
      if(this.school!="" && this.grade!=""){
        let self = this
        self.$get({
          url: self.$wp + '/unitestudy/classes',data: {
            'school_id': self.school,
            'grade': self.grade
          }
        }).then(function (res) {
          // console.log(res)
          self.optionClasses = res['data']
        })
      }
    },
    startLearn(val){
      // console.log(index)
      this.$router.push({
        name: 'class-detail', params: {val: val}
      });
    },
    pageChange(val){
      this.pageIndex = val
      this.searchCourses()
    },
    searchCourses(){
      if(this.school=="" || this.grade=="" || this.classes == ""){

        return
      }
      let self = this
      self.$get({
        url: self.$wp + '/unitestudy/courses',data: {
          'class_id': self.classes,
          'pageIndex': self.pageIndex
        }
      }).then(function (res) {
          // console.log(res)
          self.tableData = res['data']
          self.pageIndex = res['meta']['current_page']
          self.pageSize = res['meta']['per_page']
          self.pageTotal = res['meta']['total']
      })

    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-image: url("../../assets/total-bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.main-body {
  background: #fff;
  border-radius: 16px;

  .body-menu {
    padding: 30px 24px;
  }
}

.selector {
  width: 110px;
}

.btn-img{
  margin: 0 auto;
  width: 88px;
  height: 36px;
  background-image: url("../../assets/start-learn1.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  cursor: pointer;

  &:hover{
    background-image: url("../../assets/start-learn2.png")!important;
  }
}

::v-deep .el-select .el-input.is-focus .el-input__inner {
  border-color: #77C87F !important;
}

::v-deep .el-select .el-input__inner {
  border-color: #77C87F !important;
}

::v-deep .el-select .el-input__inner:focus {
  border-color: #77C87F !important;
}

::v-deep .el-input--suffix .el-input__inner {
  background: #F2FFF9 !important;
  color: #27C982 !important;
}

::v-deep .el-select .el-input .el-select__caret {
  color: #27C982 !important;
}

::v-deep .el-input__inner {
  &::placeholder {
    color: #27C982 !important;
  }
}
</style>
