<template>
    <el-row>
        <div class="main-body" v-if="type === '1'">
            <div class="e-flex body-menu">
                <img src="../../assets/test-logo2.png" alt="" class="text-logo">
                <el-select v-model="subject" placeholder="考试科目" class="e-m-l-34 selector">
                    <el-option label="全部科目" value=""></el-option>
                    <el-option
                        v-for="item in optionsSubject"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                    </el-option>
                </el-select>
                <!-- <el-select v-model="value2" placeholder="试卷标题" class="e-m-l-10 selector">
                    <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select> -->
                <div class="search-box e-m-l-10">
                    <el-input placeholder="请输入标题关键字" v-model="keyword" class="input-with-select">
                        <el-button slot="append" icon="el-icon-search" @click="search" class="search-icon"></el-button>
                    </el-input>
                </div>
            </div>
            <div class="e-p-b-30">
                <el-table
                    height="650px"
                    max-height="650px"
                    :data="tableList"
                    :header-cell-style="headerRow"
                    style="width: 100%">
                    <el-table-column
                        label="考试科目"
                        align="center"
                        prop="subject_name">
                    </el-table-column>
                    <el-table-column
                        label="试卷标题"
                        align="center"
                        prop="title">
                    </el-table-column>
                    <el-table-column label="操作" align="center">
                        <template slot-scope="scope">
                            <div class="btn-img" @click="goDetail(scope.row)"></div>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="e-m-t-20">
                    <pagination :pageIndex="pageIndex" :pageSize="pageSize" :pageTotal="pageTotal"
                                @pageChange="pageChange"></pagination>
                </div>
            </div>
        </div>
        <div class="main-body" v-else-if="type === '2'">
            <div class="e-flex body-menu">
                <img src="../../assets/test-logo.png" alt=""  class="text-logo">
                <el-select v-model="subject" placeholder="考试科目" class="e-m-l-34 selector">
                    <el-option label="全部科目" value=""></el-option>
                    <el-option
                        v-for="item in optionsSubject"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                    </el-option>
                </el-select>
                <!-- <el-select v-model="value2" placeholder="试卷标题" class="e-m-l-10 selector">
                    <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select> -->
                <div class="search-box e-m-l-10">
                    <el-input placeholder="请输入标题关键字" v-model="keyword" class="input-with-select">
                        <el-button slot="append" icon="el-icon-search" @click="initData" class="search-icon"></el-button>
                    </el-input>
                </div>
            </div>
            <div class="e-p-b-30">
                <el-table
                    height="650px"
                    max-height="650px"
                    :data="tableData"
                    :header-cell-style="headerRow"
                    style="width: 100%">
                    <el-table-column
                        label="考试科目"
                        align="center"
                        prop="subject_name">
                    </el-table-column>
                    <el-table-column
                        label="试卷标题"
                        align="center"
                        prop="test_paper_title">
                    </el-table-column>
                    <el-table-column
                        label="考试时间"
                        width="180px"
                        align="center"
                        prop="start_time">
                    </el-table-column>
                    <el-table-column
                        label="用时"
                        align="center"
                        prop="use_time">
                    </el-table-column>
                    <el-table-column
                        label="得分"
                        align="center"
                        prop="score_sum">
                        <template slot-scope="scope">
                            <p class="red-font">{{ scope.row.score_sum }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="错题数"
                        align="center"
                        prop="error_count">
                        <template slot-scope="scope">
                            <p class="yellow-font">{{ scope.row.error_count }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" align="center">
                        <template slot-scope="scope">
                            <div class="btn-go-log-img" @click="goLogDetail(scope.row)"></div>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="e-m-t-20">
                    <pagination :pageIndex="pageIndex" :pageSize="pageSize" :pageTotal="pageTotal"
                                @pageChange="pageChange"></pagination>
                </div>
            </div>
        </div>

        <el-dialog
            :visible.sync="popup"
            :show-close="false"
            width="30%">
            <div class="popup-box t-rela">
                <img src="../../assets/detail/close.png" alt="" class="close-img pointer" @click="popup = false">
                <div class="e-text-center">
                    <img src="../../assets/test/tips.png" alt="">
                </div>
                <div class="main-text e-text-center e-weight-font e-p-l-80 e-p-r-80 e-m-t-30 e-font-18">
                    开始答题后会直接进入考试,有考试时间限制并且不可再返回学习, 确认是否开始答题?
                </div>
                <div class="e-flex e-flex-center e-m-t-30">
                    <img src="../../assets/test/cancel-btn.png" alt="" @click="popup = false" class="pointer">
                    <img src="../../assets/test/go-test-btn.png" alt="" @click="goExamination" class="pointer">
                </div>
            </div>
        </el-dialog>
    </el-row>
</template>

<script>
import Pagination from "@/components/pagination"

export default {
    name: "test",
    components: {
        Pagination
    },
    data() {
        return {
            popup: false,
            type: '',
            pageIndex: 1,
            pageSize: 10,
            pageTotal: 0,
            optionsSubject:[],
            subject:'',
            keyword: '',
            tableData: [],
            tableList: [],
            headerRow: {
                background: '#F2FBF7',
                padding: '20px 0',
                color: '#05170F',
                fontSize: '14px'
            },
            currentRow:{},

        }
    },
    created() {
        //初始化分页
        this.pageIndex = 1;

        //初始化考试科目和试卷标题
        this.initOption();
        this.type = sessionStorage.getItem('typeNow');
        if (this.type === '1') {
            this.initList();
        } else {
            this.initData();
        }
    },
    activated(){
        if(this.type != sessionStorage.getItem('typeNow')){
            this.pageIndex = 1;
            this.pageTotal = 0;
        }
        this.type = sessionStorage.getItem('typeNow');
        if (this.type === '1') {
            this.initList();
        } else {
            this.initData();
        }
    },
    methods: {
        initOption() {
            // console.log('初始化学校年级')
            let that = this;
            that.$post({
                url: that.$wp + '/subject',
                data: {}
            }).then(function (res) {
                console.log('subject',res)
                that.optionsSubject = res.data;
            })
        },
        initList: function () {
            let that = this;
            //考试列表

            that.$post({
                url: that.$wp + '/test-paper',
                // headers: {
                //     Authorization: 'Bearer ' + localStorage.getItem('token')
                // },
                data:{
                    subject_id:that.subject,
                    title:that.keyword,
                    page: that.pageIndex,
                    page_size: that.pageSize
                }
            }).then(function (res) {
                that.tableList = res.data;
                that.pageIndex = res.meta.current_page;
                that.pageTotal = res.meta.total;
            })
        },
        initData: function () {
            let that = this;
            //历史考卷

            that.$post({
                url: that.$wp + '/test-paper-log',
                // headers: {
                //     Authorization: 'Bearer' + localStorage.getItem('token')
                // },
                data: {
                    subject_id:that.subject,
                    title:that.keyword,
                    page: that.pageIndex,
                    page_size: that.pageSize
                }
            }).then(function (res) {
                console.log('loglist',res)
                that.tableData = res.data;
                that.pageIndex = res.meta.current_page;
                that.pageTotal = res.meta.total;
            })
        },
        search(){
            if (this.type === '1') {
                this.initList();
            } else {
                this.initData();
            }
        },
        pageChange(val) {
            this.pageIndex = val
            if (this.type === '1') {
                this.initList();
            } else {
                this.initData();
            }
        },
        goDetail(val) {
            console.log(val)
            this.currentRow = val
            this.popup = true
        },
        goLogDetail(val){
            this.$router.push({
                name: 'examination',params: {val: val,logview:1}
            })
            this.popup = false
        },
        goExamination() {
            this.$router.push({
                name: 'examination',params: {val: this.currentRow}
            })
            this.popup = false
        }
    }
}
</script>

<style lang="scss" scoped>
.main-body {
    background: #fff;
    border-radius: 16px;

    .body-menu {
        padding: 30px 24px;
    }
}

.selector {
    width: 110px;
}

.close-img {
    position: absolute;
    right: 30px;
    top: 30px;
}

.btn-img {
    margin: 0 auto;
    width: 88px;
    height: 36px;
    background-image: url("../../assets/test/start-test1.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    cursor: pointer;

    &:hover {
        background-image: url("../../assets/test/start-test2.png") !important;
    }
}

.btn-go-log-img {
    margin: 0 auto;
    width: 88px;
    height: 36px;
    background-image: url("../../assets/test/log-test2.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    cursor: pointer;

    &:hover {
        background-image: url("../../assets/test/log-test1.png") !important;
    }
}

.text-logo{
    width: 108px;
    height: 40px;
}

.red-font {
    color: #F54857;
}

.yellow-font {
    color: #FAC517;
}

::v-deep .el-select .el-input.is-focus .el-input__inner {
    border-color: #77C87F !important;
}

::v-deep .el-select .el-input__inner {
    border-color: #77C87F !important;
}

::v-deep .el-select .el-input__inner:focus {
    border-color: #77C87F !important;
}

::v-deep .el-input--suffix .el-input__inner {
    background: #F2FFF9 !important;
    color: #27C982 !important;
}

::v-deep .el-select .el-input .el-select__caret {
    color: #27C982 !important;
}

::v-deep .el-input__inner {
    &::placeholder {
        color: #27C982 !important;
    }
}

::v-deep .el-input-group--append .el-input__inner {
    border-color: #77C87F !important;
}

::v-deep .el-input-group__append {
    background: #27C982;
    border-color: #27C982;
    color: #fff;
    font-size: 16px;
}

::v-deep .el-dialog__header {
    display: none !important;;
}

::v-deep .el-dialog {
    border-radius: 10px !important;
}

::v-deep .el-dialog__body {
    padding: 36px 30px !important;
}
</style>
