<template>
    <el-row class="main-body">
        <div class="e-flex body-menu">
            <img src="../../assets/class-list.png" alt="">
            <el-select v-model="subject" placeholder="选择科目" class="e-m-l-34 selector">
                <el-option label="全部科目" value=""></el-option>
                <el-option
                    v-for="item in optionsSubject"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                </el-option>
            </el-select>
            <!-- <el-select v-model="value2" placeholder="选择课程" class="e-m-l-10 selector">
                <el-option
                    v-for="item in optionsCourses"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
            </el-select> -->
            <div class="search-box e-m-l-10">
                <el-input placeholder="请输入内容" v-model="keyword" class="input-with-select">
                    <el-button slot="append" icon="el-icon-search" @click="initData" class="search-icon"></el-button>
                </el-input>
            </div>
        </div>
        <div class="e-p-b-30">
            <el-table
                max-height="650px"
                :data="tableMu"
                :header-cell-style="headerRow"
                style="width: 100%">
                <el-table-column
                    label="课程序号"
                    align="center"
                    prop="number">
                </el-table-column>
                <el-table-column
                    label="科目"
                    align="center"
                    prop="subject_name">
                </el-table-column>
                <el-table-column
                    label="课程名称"
                    align="center"
                    prop="name">
                </el-table-column>
                <el-table-column
                    label="学习时间"
                    align="center"
                    prop="study_time">
                </el-table-column>
                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <div class="btn-img" @click="goDetail(scope.row)"></div>
                    </template>
                </el-table-column>
            </el-table>
            <div class="e-m-t-20">
                <pagination :pageIndex="pageIndex" :pageSize="pageSize" :pageTotal="pageTotal"
                            @pageChange="pageChange"></pagination>
            </div>
        </div>
    </el-row>
</template>

<script>
import Pagination from "@/components/pagination"

export default {
    name: "online-study",
    components: {
        Pagination
    },
    data() {
        return {
            pageIndex: 1,
            pageSize: 8,
            pageTotal: 0,
            keyword: '',
            optionsSubject:[],
            optionsCourses:[],
            subject:'',
            classes:'',
            // options: [{
            //     value: '选项1',
            //     label: '黄金糕'
            // }],
            // value: '',
            // value2: '',
            tableMu: [],
            tableData: [],
            headerRow: {
                background: '#F2FBF7',
                padding: '20px 0',
                color: '#05170F',
                fontSize: '14px'
            }
        }
    },
    created() {
        //数据初始化
        this.initOption();
        this.initData();
    },
    activated() {
        this.initOption();
        this.initData();
    },
    methods: {
        initOption() {
            // console.log('初始化学校年级')
            let that = this;
            that.$post({
                url: that.$wp + '/subject',
                data: {}
            }).then(function (res) {
                // console.log('subject',res)
                that.optionsSubject = res.data;
            })
        },
        initData() {
            let that = this;

            that.$post({
                url: that.$wp + '/student-course',
                data: {
                    name: that.keyword,
                    subjects_id: that.subject, //科目id
                    page: that.pageIndex,
                    page_size: that.pageSize, //每页条数
                }
            }).then(function (res) {
                // console.log('courses',res)
                that.tableMu = res.data;
                that.pageIndex = res.meta.current_page;
                that.pageTotal = res.meta.total;
            })
        },
        
        goDetail(val) {
            this.$router.push({
                name: 'class-detail', params: {val: val}
            })
        },
        pageChange(val) {
            this.pageIndex = val
            this.initData();
        }
    }
}
</script>

<style lang="scss" scoped>
.main-body {
    background: #fff;
    border-radius: 16px;

    .body-menu {
        padding: 30px 24px;
    }
}

.selector {
    width: 110px;
}

.btn-img {
    margin: 0 auto;
    width: 88px;
    height: 36px;
    background-image: url("../../assets/start-learn1.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    cursor: pointer;

    &:hover {
        background-image: url("../../assets/start-learn2.png") !important;
    }
}

::v-deep .el-select .el-input.is-focus .el-input__inner {
    border-color: #77C87F !important;
}

::v-deep .el-select .el-input__inner {
    border-color: #77C87F !important;
}

::v-deep .el-select .el-input__inner:focus {
    border-color: #77C87F !important;
}

::v-deep .el-input--suffix .el-input__inner {
    background: #F2FFF9 !important;
    color: #27C982 !important;
}

::v-deep .el-select .el-input .el-select__caret {
    color: #27C982 !important;
}

::v-deep .el-input__inner {
    &::placeholder {
        color: #27C982 !important;
    }
}

::v-deep .el-input-group--append .el-input__inner {
    border-color: #77C87F !important;
}

::v-deep .el-input-group__append {
    background: #27C982;
    border-color: #27C982;
    color: #fff;
    font-size: 16px;
}
</style>
