<template>
    <el-row class="container">
        <!-- <total-head :froms="'unite-study'"></total-head> -->
        <div class="e-flex">
            <p class="e-font-24 e-weight-font main-text">{{ tableMu.name }}</p>
            <div class="e-flex-right">
                <img src="../../assets/detail/close.png" alt="" @click="goBack" class="pointer">
            </div>
        </div>
        <img :src="tableMu.cover" alt="" class="class-img e-m-t-22">
        <!--        <img src="../../assets/detail/class-detail.png" alt="" class="class-img e-m-t-22">-->
        <img src="../../assets/class-desc-img.png" alt="" class="e-m-t-32">
        <div class="desc" v-html="tableMu.desc"></div>
        <div class="btn-img e-m-t-24" @click="study"></div>

        <!-- 课程列表弹窗 -->
        <el-dialog
            :visible.sync="dialogVisible"
            :show-close="false"
            width="53%">
            <div class="popup-box">
                <div class="e-flex">
                    <p class="e-font-24 e-weight-font main-text">{{ tableMu.name }}</p>
                    <div class="e-flex-right">
                        <img src="../../assets/detail/close.png" alt="" @click="dialogVisible = false" class="pointer">
                    </div>
                </div>
                <div class="data-list e-flex e-flex-wrap">
                    <div class="data-item " v-for="item in tableMu.lists" :key="item.id">
                        <img v-if="item.type == 1" :src="item.list[0]" alt="" class="poster">
                        <div class="poster videos" v-else>
                        <img v-if="item.video_cover" :src="item.video_cover" alt="" class="poster">
                            <div class="mask">
                                <img src="../../assets/play-btn.png" alt="">
                            </div>
                        </div>
                        <div class="data-foot e-flex">
                            <p class="e-font-14 main-text">{{ cutStr(item.title) }}</p>
                            <img v-if="item.type == 1" src="../../assets/watch-btn.png" alt="" class="e-flex-right"
                                 @click="openImgs(item.list)">
                            <img v-else src="../../assets/watch-btn.png" alt="" class="e-flex-right"
                                 @click="openVideos(item.url)">
                        </div>
                    </div>
                </div>
            </div>
        </el-dialog>

        <!-- 图片列表弹窗 -->
        <el-dialog
            :visible.sync="dialogVisible2"
            :show-close="false"
            width="58%">
            <div class="popup-box">
                <div class="e-flex">
                    <div class="e-flex-right">
                        <img src="../../assets/detail/close.png" alt="" @click="dialogVisible2 = false" class="pointer">
                    </div>
                </div>
                <div class="img-info">
                    <div class="e-flex e-flex-between">
                        <img src="../../assets/prev-btn.png" alt="" @click="imgview_prev" class="prev">
                        <!--<img :src="temporary[current_view]" alt="" class="img-now">-->
                        <el-image :src="temporary[current_view]" :preview-src-list="temporary" :z-index="999999" class="img-now"></el-image>
                        <img src="../../assets/next-btn.png" alt="" @click="imgview_next">
                    </div>
                    <div class="img-list e-flex-nowrap e-m-t-30">
                        <div class="img-box" v-for="item in temporary" :key="item" :class="{'img-active':item === 10}">
                            <img :src="item" alt="" class="img-item" style="object-fit:cover">
                        </div>
                    </div>
                </div>
            </div>
        </el-dialog>

        <!-- 视频弹窗 -->
        <el-dialog
            :visible.sync="dialogVisible3"
            :show-close="false"
            @close="now_video = ''"
            width="58%">
            <div class="popup-box">
                <div class="e-flex">
                    <div class="e-flex-right">
                        <img src="../../assets/detail/close.png" alt="" @click="dialogVisible3 = false" class="pointer">
                    </div>
                </div>
                <div class="videos">
                     <video controls="controls" controlsList="nodownload" autoplay="autoplay" :src="now_video" class="videos" loop>
                        <!-- <source :src="now_video" type="video/wmv"/> -->
                    </video>
                    <!-- <video :src="now_video"></video> -->
                </div>
            </div>
        </el-dialog>
    </el-row>
</template>

<script>
export default {
    name: "class-detail",
    data() {
        return {
            dialogVisible: false,
            dialogVisible2: false,//图片详情
            dialogVisible3: false,//视频
            tableMu: {tableMu: '', name: '', desc: ''},
            temporary: [], //图片路径临时数组
            current_view: 0,//图片浏览-当前序号
            now_video: '',
        }
    },
    computed: {
        cutStr() {
            return function (val) {
                if (val.length > 8) {
                    return val.substring(0, 8) + '...'
                } else {
                    return val
                }
            }
        }
    },
    created() {
        console.log('created')
    },
    mounted() {
        let params = this.$route.params
        console.log("params", params)
        this.tableMu = params.val
        this.initDetail(params.val.id)
    },
    activated(){
        let params = this.$route.params
        console.log("params", params)
        this.initDetail(params.val.id);
    },
    methods: {
        initDetail(x) {
            // console.log('csh')
            let that = this;

            that.$get({
                url: that.$wp + '/course/'+x,
                // data: {
                //     // id:x,
                //     course: x,
                // }
            }).then(function (res) {
                console.log('tableMu',res)
                that.tableMu.lists = res.data.lists;
            })
        },
        goBack() {
            this.$router.back()
        },
        study() {
            this.dialogVisible = true;

            //    加载数据
            //     this.$post()
        },
        openImgs(e) {
            // this.dialogVisible = false
            this.dialogVisible2 = true
            this.current_view = 0
            this.temporary = e;
        },
        imgview_prev(){
            this.current_view >0 && this.current_view--
        },
        imgview_next(){
            this.current_view < this.temporary.length-1 && this.current_view++
        },
        openVideos(u) {
            // this.dialogVisible = false
            this.dialogVisible3 = true
            this.now_video = u
        }
    }
}
</script>

<style lang="scss" scoped>
.container {
    padding: 30px 40px;
    border-radius: 10px;
    background: #fff;
    height: auto;
}

.class-img {
    width: 100%;
    height: 672px;
}

.desc {
    font-size: 14px;
    color: #05170F;
    line-height: 2;
}

.btn-img {
    margin: 0 auto;
    width: 88px;
    height: 36px;
    background-image: url("../../assets/start-learn1.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    cursor: pointer;

    &:hover {
        background-image: url("../../assets/start-learn2.png") !important;
    }
}

::v-deep .el-dialog__header {
    display: none !important;;
}

::v-deep .el-dialog {
    border-radius: 10px !important;
}

::v-deep .el-dialog__body {
    padding: 36px 30px !important;
}

.popup-box {

    .data-list {
        background: #F7F5F1;
        padding: 10px 10px 30px 30px;
        height: 500px;
        overflow: auto;

        .data-item {
            cursor: pointer;
            margin-top: 20px;
            margin-right: 20px;

            .poster {
                width: 280px;
                height: 184px;
                border-radius: 10px 10px 0 0;
            }

            .videos {
                background-image: url("../../assets/examples/examples.png");
                background-size: 100% 100%;
                background-repeat: no-repeat;
                position: relative;

                .mask {
                    border-radius: 10px 10px 0 0;
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    background: rgba(0, 0, 0, 0.5);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            .data-foot {
                margin-top: -6px;
                padding: 16px 20px;
                background: #fff;
                border-radius: 0 0 10px 10px;
            }
        }
    }

    .img-now {
        width: 940px;
        height: 600px;
    }

    .img-list {
        background: #F7F5F1;
        padding: 20px;
        overflow-x: scroll;
        display: inline-flex;
        width: 95%;

        .img-box {
            margin: 0 10px;
            width: 91px;
            height: 116px;
            text-align: center;
        }

        .img-item {
            width: 91px;
            height: 116px;
        }
    }

    .img-active {
        border: 4px solid #27C982;
    }

    .videos {
        width: 100%;
        height: 600px;
    }
}

</style>
