import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import './utils/rem'
import wp from './wp'

Vue.use(wp)
// Vue.prototype.$wp = 'http://ces.um/api'
/**/Vue.prototype.$wp = 'http://tongan.com/api'
Vue.prototype.$wp = 'https://test-wave-live.ynlky.cn/api'
// Vue.prototype.$wp = 'http://jiaoyu.yntongan.com/api'
Vue.config.productionTip = false

new Vue({
	router,
	store,
	render: function (h) { return h(App) },
}).$mount('#app')
