<template>
  <div class="container">
    <total-head :froms="'index'" ref="totalHead"></total-head>
    <el-row class="e-flex e-flex-center e-m-t-30">
      <el-col :span="14">
        <router-view @currentChange="currentChange"/>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import TotalHead from "@/components/total-head"
export default {
  name: "index",
  components:{
    TotalHead
  },
  data(){
    return{
      currents:0
    }
  },
  mounted() {

  },
    methods:{
        currentChange(e){
            console.log(e)
            this.$refs.totalHead.current = e
        }
    }
}
</script>

<style lang="scss" scoped>
  .container{
    width: 100%;
    height: 100%;
    background-image: url("../../assets/total-bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding-bottom: 30px;
  }
</style>
