import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/login/login'
import Index from '@/views/index/index'
import UniteStudy from "@/views/index/unite-study"
import Home from "@/views/index/home"
import OnlineStudy from "@/views/index/online-study"
import ClassDetail from "@/views/index/class-detail"
import Test from "@/views/index/test"
import Examination from "@/views/index/examination"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login
  },
  {
    path: '/index',
    name: 'index',
    component: Index,
    children: [
      {
        path:'home',
        name:'home',
        component:Home
      },
      {
        path:'online-study',
        name:'online-study',
        component:OnlineStudy
      },
      {
        path: 'class-detail',
        name: 'class-detail',
        component: ClassDetail
      },
      {
        path: 'test',
        name: 'test',
        component: Test
      }
    ]
  },
  {
    path: '/unite-study',
    name: 'unite-study',
    component: UniteStudy
  },
  {
    path: '/examination',
    name: 'examination',
    component: Examination
  }
]

const router = new VueRouter({
  mode: 'hash',
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
